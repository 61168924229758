<template>
  <div>
    <div v-if="loading">
      <RWCard title="Please wait">
        <template #body>
          <v-skeleton-loader type="heading"></v-skeleton-loader> <br />
          <v-skeleton-loader type="paragraph@2"></v-skeleton-loader>
        </template>
      </RWCard>
    </div>
    <RWCard :title="news.title" v-else>
      <template #body>
        <div v-html="news.announcement"></div>
        <h6 class="font-weight-bold mt-5">{{ news.date }}</h6>
      </template>
      <template #foot>
        <b-link :to="{ name: 'news-all' }"
          ><span class="fa fa-angle-double-left"></span> All news</b-link
        >
      </template>
    </RWCard>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { breadcrumb, ApiURL } from "@/common/mixins/general.js";
export default {
  mixins: [breadcrumb, ApiURL],
  components: { RWCard },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      news: {}
    };
  },
  methods: {
    get_news_single() {
      this.xhr_news("index", this.id)
        .then(({ data }) => {
          this.news = data.data.news;
        })
        .finally(() => {
          this.loading = false;
          this.set_breadcrumb([
            { title: "News", route: "news" },
            { title: this.news.title }
          ]);
        });
    }
  },
  mounted() {
    this.get_news_single();
  }
};
</script>
